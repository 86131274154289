<template>
    <div></div>
</template>
<script>
import { Field, Toast } from 'vant';
import { mapGetters, mapActions } from 'vuex';
import api from '@/api/login/loginApi';
export default {
    data () {
        return {
            id:"",
			phone:"",
			url:"",
        }
    },
    created() {
        let {id,p,url} = this.$route.query;
		this.id = id;
		this.phone = p;
		this.url = decodeURIComponent(url);
		// console.log(this.id,this.phone,this.url)
		if(localStorage.getItem('healthme_token')||!this.phone||!this.id){
			window.location.href = this.url;
		}else if(!this.url){
			this.toindex();
		}else{
			this.toLogin();
		}
    },
    computed:{
    },
    methods: {
        toLogin() {
			Toast.loading({
			  message: '登录中',
			});
            api.userautoRegister({weixinUserId:this.id,mobile:this.phone}).then(res => {
				Toast.clear();
				if(res.data.token){
					localStorage.setItem('healthme_token', res.data.token)
					this.$store.commit('SET_TOKEN', res.data.token);
					window.location.href = this.url;
				}else{
					this.toindex();
				}
            }).catch(err => {
				Toast.clear();
                this.toindex();
            })
        },
		toindex(){
			window.location.href = window.location.origin;
		},
    }
}
</script>
<style lang="less" scoped>

</style>

